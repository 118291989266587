'use client';

import { Flex, Text, rem, Button, Grid, Avatar } from '@mantine/core';
import Image from 'next/image';
import Link from 'next/link';
import {
  SignOut,
  ChartPieSlice,
  ShoppingBag,
  Folder,
  BookOpen,
  IdentificationCard,
  UserCircle,
  File,
  Kanban,
  CaretRight,
  Gear,
  AlignLeft,
  TreeStructure,
  GlobeHemisphereEast,
  Nut,
  GearSix,
  UserGear,
  Toolbox,
  Hammer,
  Coins,
  Blueprint,
  City,
  Bulldozer,
  Cards,
} from '@phosphor-icons/react/dist/ssr';
import { useRouter } from 'next/navigation';
import { useUser } from '@/hooks/useUser';
import classes from './Navigation.module.css';
import defaultImage from '@/public/images/default_image.svg';
import { useTranslation } from '@/hooks';
import { NavigationItem } from './NavigationItem';
import { useStore } from '@/hooks/store';
import { ROLES } from '@/constants/roles';
import { Stack, Wrench } from '@phosphor-icons/react';

const ICONS: any = {
  ADMINISTRATORS: {
    icon: <UserCircle size={25} />,
    label: 'Administrātors',
  },
  GENERALUZNEMEJS: {
    icon: <GlobeHemisphereEast size={25} />,
    label: 'Ģenerāluzņēmējs',
  },
  'MATERIALU_RAZOTAJS (LEGAL PERSON)': {
    icon: <Stack size={25} />,
    label: 'Materiālu ražotājs (Juridiska persona)',
  },
  'MATERIALU_RAZOTAJS (EMPLOYEE)': {
    icon: <Nut size={25} />,
    label: 'Materiālu ražotājs (Darbinieks)',
  },
  'MEHANISMU_IZNOMATAJS (LEGAL PERSON)': {
    icon: <GearSix size={25} />,
    label: 'Mehānismu iznomātājs (Juridiska persona)',
  },
  'MEHANISMU_IZNOMATAJS (EMPLOYEE)': {
    icon: <UserGear size={25} />,
    label: 'Mehānismu iznomātājs (Darbinieks)',
  },
  APAKSUZNEMEJS: {
    icon: <TreeStructure size={25} />,
    label: 'Apakšuzņēmējs',
  },
  'PROJEKTU_VADITAJS (EMPLOYEE)': {
    icon: <Kanban size={25} />,
    label: 'Projektu vadītājs',
  },
  'DARBU_VADITAJS (EMPLOYEE)': {
    icon: <Toolbox size={25} />,
    label: 'Darbu vadītājs (Darbinieks)',
  },
  'BRIGADIERIS (EMPLOYEE)': {
    icon: <Hammer size={25} />,
    label: 'Brigadieris',
  },
  'TAMETAJS (EMPLOYEE)': {
    icon: <Coins size={25} />,
    label: 'Tāmētājs',
  },
};

export const Navigation = () => {
  const user = useUser((state) => state.user);
  const router = useRouter();
  const { currentRole, setRoleSelectorVisible } = useStore();
  const { t } = useTranslation();

  return (
    <div className={classes.navigation}>
      <Flex direction="column">
        {/* <Image
          className={classes.logo}
          src={logo}
          height={60}
          width={150}
          alt="e-tame"
        /> */}
        <Text fz={rem(35)} fw={600} c="#000000">
          etame
        </Text>
      </Flex>
      <Flex data-type="navigation" direction="column" justify="space-between">
        <div>
          <Flex
            align="center"
            mb={rem(10)}
            onClick={() => router.push('/profile')}
            style={{ cursor: 'pointer' }}
          >
            <Avatar
              variant="light"
              mr={rem(10)}
              mb={rem(10)}
              color="initials"
            />
            <Flex direction="column">
              <Text fz={rem(13)} fw={600} c="black">
                {user?.name} {user?.surname}
              </Text>
              <Text fz={rem(13)} fw={500} c="grey" mb={rem(10)}>
                {user?.email}
              </Text>
            </Flex>
          </Flex>
          <Text fz={rem(14)} fw={400} c="#8F9098">
            Izvēlne
          </Text>
          <NavigationItem
            path="/home"
            label="Sākums"
            icon={<ChartPieSlice weight="fill" />}
            permissions={[
              ROLES.ADMINISTRATORS,
              ROLES.MATERIALU_RAZOTAJS_LEGAL,
              ROLES.MATERIALU_RAZOTAJS_EMPLOYEE,
              ROLES.GENERALUZNEMEJS,
              ROLES.MEHANISMU_IZNOMATAJS_LEGAL,
              ROLES.MEHANISMU_IZNOMATAJS_EMPLOYEE,
              ROLES.APAKSUZNEMEJS,
              ROLES.PROJEKTU_VADITAJS,
              ROLES.DARBU_VADITAJS,
              ROLES.BRIGADIERIS,
              ROLES.TAMETAJS,
            ]}
          />
          <NavigationItem
            path="/objects"
            label="Objekti"
            icon={<ShoppingBag />}
            permissions={[
              ROLES.ADMINISTRATORS,
              ROLES.MATERIALU_RAZOTAJS_LEGAL,
              ROLES.MATERIALU_RAZOTAJS_EMPLOYEE,
              ROLES.GENERALUZNEMEJS,
              ROLES.MEHANISMU_IZNOMATAJS_LEGAL,
              ROLES.MEHANISMU_IZNOMATAJS_EMPLOYEE,
              ROLES.APAKSUZNEMEJS,
              ROLES.PROJEKTU_VADITAJS,
              ROLES.DARBU_VADITAJS,
              ROLES.BRIGADIERIS,
              ROLES.TAMETAJS,
            ]}
          >
            {/* <NavigationItem
              path="/one"
              label='viens'
              icon={<ShoppingBag />}
            /> */}
            {/* <NavigationItem
              path="/two"
              label='divi'
              icon={<ShoppingBag />}
            /> */}
          </NavigationItem>
          <NavigationItem
            path="/settings"
            label="Iestatījumi"
            icon={<Gear weight="duotone" />}
            permissions={[
              ROLES.ADMINISTRATORS,
              ROLES.MATERIALU_RAZOTAJS_LEGAL,
              ROLES.MATERIALU_RAZOTAJS_EMPLOYEE,
              ROLES.GENERALUZNEMEJS,
              ROLES.MEHANISMU_IZNOMATAJS_LEGAL,
              ROLES.MEHANISMU_IZNOMATAJS_EMPLOYEE,
              ROLES.APAKSUZNEMEJS,
              ROLES.PROJEKTU_VADITAJS,
              ROLES.DARBU_VADITAJS,
              ROLES.BRIGADIERIS,
              ROLES.TAMETAJS,
            ]}
          >
            <NavigationItem
              path="/settings/users"
              icon={<UserCircle />}
              label="Lietotāju pārvaldība"
              permissions={[
                ROLES.ADMINISTRATORS,
                ROLES.MATERIALU_RAZOTAJS_LEGAL,
                ROLES.GENERALUZNEMEJS,
                ROLES.MEHANISMU_IZNOMATAJS_LEGAL,
                ROLES.APAKSUZNEMEJS,
              ]}
            />
            <NavigationItem
              path="/materials"
              label="Materiāli"
              icon={<Folder weight="duotone" />}
            />
            <NavigationItem
              path="/mechanisms"
              label="Mehānismi"
              icon={<Wrench weight="duotone" />}
            />
            <NavigationItem
              path="/systems"
              label="Sistēmas"
              icon={<Cards weight="duotone" />}
            />
            <NavigationItem
              path="/#"
              label="Klasifikatori"
              icon={<BookOpen weight="duotone" />}
              permissions={[
                ROLES.ADMINISTRATORS,
                ROLES.MATERIALU_RAZOTAJS_LEGAL,
                ROLES.MATERIALU_RAZOTAJS_EMPLOYEE,
                ROLES.GENERALUZNEMEJS,
                ROLES.MEHANISMU_IZNOMATAJS_LEGAL,
                ROLES.MEHANISMU_IZNOMATAJS_EMPLOYEE,
                ROLES.APAKSUZNEMEJS,
                ROLES.PROJEKTU_VADITAJS,
                ROLES.DARBU_VADITAJS,
                ROLES.BRIGADIERIS,
                ROLES.TAMETAJS,
              ]}
            >
              <NavigationItem
                path="/settings/classificators"
                icon={<City />}
                label="Būvju klasifikācija"
              />
              <NavigationItem
                path="/settings/projects"
                icon={<Blueprint />}
                label="Būvniecības ieceres dokumentācijas markas"
              />
              <NavigationItem
                path="/settings/elements"
                icon={<AlignLeft />}
                label="Būvdarbu un konstruktīvo elementu veidi"
              />
              <NavigationItem
                path="/settings/worktypes"
                icon={<Bulldozer />}
                label="Būvdarbu veidi"
              />
            </NavigationItem>
          </NavigationItem>
        </div>
        <div>
          <Button
            mb={rem(20)}
            w={'100%'}
            variant="outline"
            c="#000000"
            onClick={() => setRoleSelectorVisible(true)}
            rightSection={<CaretRight size={15} />}
          >
            <Grid ml={rem(20)} justify="flex-start" align="center">
              <Grid.Col span={1} mr={rem(25)}>
                {ICONS[currentRole]?.icon}
              </Grid.Col>
              <Grid.Col span={3}>
                <Text fz={rem(14)} fw={600}>
                  {ICONS[currentRole]?.label}
                </Text>
              </Grid.Col>
            </Grid>
          </Button>
          <NavigationItem path="/logout" label="Iziet" icon={<SignOut />} />
        </div>
      </Flex>
    </div>
  );
};
